import React, { Suspense } from 'react';
import {
  Route,
  Routes
} from 'react-router-dom';
import {
  RTE_DASHBOARD,
  RTE_DEMO_FASTER,
  RTE_DEMO_SSE,
  RTE_DEMO_TYPE_AHEAD_WIDGET,
  RTE_SECONDARY_REPORTING,
  RTE_SFR,
  RTE_SFR_PRIOR_YRS,
  RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORT,
  RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORTS,
  RTE_SFR_SUB_CAT_SURVEY_PRD,
  RTE_SFR_SUB_CAT_SURVEY_PRD_REPORT,
  RTE_SFR_SUB_CAT_SURVEY_PRD_REPORTS,
  RTE_STX
} from '../const';
import {
  CurrentTenant,
  CurrentUser,
  HandleError,
  HandleSuccess,
  NavigateToUrl,
  STXWidgetProps,
  UpdateSFRConfig,
  UpdateSFRLog,
  UpdateSTXRequests
} from '../models';
import DrawLoader from './shared/draw-loader';

// Code splitting to reduce bundle size to help with page load performance
const Dashboard = React.lazy(() => import('./dashboard/dashboard'));
const STXMain = React.lazy(() => import('./stx/stx-main'));
const SFRAllSubmissions = React.lazy(() => import('./sfr/sfr-all-submissions'));
const SFRPriorYearSubmissions = React.lazy(() => import('./sfr-prior-year-submissions/sfr-prior-year-submissions'));
const SecondaryReporting = React.lazy(() => import('./secondary-reporting/secondary-reporting'));
const SFRCategorySurvey = React.lazy(() => import('./sfr/sfr-category-survey'));
const SFRCategorySurveyReports = React.lazy(() => import('./sfr/sfr-category-survey-reports'));
const SFRCategorySurveyReport = React.lazy(() => import('./sfr/sfr-category-survey-report'));

// These are for demos only and will not be active in production
const DemoFaster = React.lazy(() => import('./demo-faster'));
const DemoSse = React.lazy(() => import('./demo-sse'));
const DemoTypeAheadWidget = React.lazy(() => import('./demo-type-ahead-widget'));

// Define small Props and State in component file to reduce number of unnecessary model files
type Props = {}
  & NavigateToUrl
  & HandleError
  & HandleSuccess
  & CurrentTenant
  & CurrentUser
  & STXWidgetProps
  & UpdateSTXRequests
  & UpdateSFRConfig
  & UpdateSFRLog;

/**
 * App Routes component as a stateless function
 *
 * @param props
 */
export default function AppRoutes(props: Props) {
  // Destructing Assignment
  const {
    navigateToUrl,
    handleError,
    handleSuccess,
    stxStatus,
    stxStateWidgetLabel,
    currentTenant,
    currentUser,
    updateSTXRequestsCounter,
    updateSFRConfigCounter,
    updateSFRLogCounter
  } = props;

  // TODO: make these dynamic based on state context
  const stxMainStateLabel = 'FASTER';
  const stxMainStateStudentIdentifierLabel = 'FLEID';

  return (
    <main>
      { /* Message Area */ }
      <pds-messages-widget data-message-location="FLOATING" data-can-dismiss="true" />
      { /* Code split is implemented on the routes */ }
      <Suspense fallback={ <DrawLoader size="small" /> }>
        <Routes>
          <Route
            path={ RTE_DASHBOARD }
            element={
              (
                <Dashboard
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  stxStateWidgetLabel={ stxStateWidgetLabel }
                  stxStatus={ stxStatus }
                />
              )
            }
          />
          <Route
            path={ RTE_STX }
            element={
              (
                <STXMain
                  stateLabel={ stxMainStateLabel }
                  stateStudentIdentifierLabel={ stxMainStateStudentIdentifierLabel }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  currentUser={ currentUser }
                  updateSTXRequestsCounter={ updateSTXRequestsCounter }
                />
              )
            }
          />
          <Route
            path={ RTE_SFR }
            element={
              (
                <SFRAllSubmissions
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  updateSFRConfigCounter={ updateSFRConfigCounter }
                  updateSFRLogCounter={ updateSFRLogCounter }
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_SUB_CAT_SURVEY_PRD }
            element={
              (
                <SFRCategorySurvey
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  updateSFRConfigCounter={ updateSFRConfigCounter }
                  updateSFRLogCounter={ updateSFRLogCounter }
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_SUB_CAT_SURVEY_PRD_REPORTS }
            element={
              (
                <SFRCategorySurveyReports
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  currentOrPriorYear="current"
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_SUB_CAT_SURVEY_PRD_REPORT }
            element={
              (
                <SFRCategorySurveyReport
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  currentOrPriorYear="current"
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_PRIOR_YRS }
            element={
              (
                <SFRPriorYearSubmissions
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  updateSFRConfigCounter={ updateSFRConfigCounter }
                  updateSFRLogCounter={ updateSFRLogCounter }
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORTS }
            element={
              (
                <SFRCategorySurveyReports
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  currentOrPriorYear="prior"
                />
              )
            }
          />
          <Route
            path={ RTE_SFR_PRIOR_YRS_SUB_CAT_SURVEY_PRD_REPORT }
            element={
              (
                <SFRCategorySurveyReport
                  navigateToUrl={ navigateToUrl }
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                  currentOrPriorYear="prior"
                />
              )
            }
          />
          <Route
            path={ RTE_SECONDARY_REPORTING }
            element={
              (
                <SecondaryReporting
                  handleError={ handleError }
                  currentTenant={ currentTenant }
                />
              )
            }
          />
          { /* These below are for demo only and will not be active in production */ }
          <Route path={ RTE_DEMO_SSE } element={ <DemoSse /> } />
          <Route
            path={ RTE_DEMO_FASTER }
            element={ <DemoFaster handleError={ handleError } handleSuccess={ handleSuccess } /> }
          />
          <Route path={ RTE_DEMO_TYPE_AHEAD_WIDGET } element={ <DemoTypeAheadWidget /> } />
        </Routes>
      </Suspense>
    </main>
  );
}
